import {
    AsyncDropDownPaginated,
    LogLevel,
    lookupValidator,
    OptionTypeBase,
    OptionTypeBaseUserFormatter,
    PendingButton,
    SearchQuery,
    showBanner,
    Sisp,
    SortOrder,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { BaseSyntheticEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FieldValues, useForm } from 'react-hook-form';
import { DictionaryContext, RepositoryFactoryContext } from '../..';
import IEngagementMethod from '../../../../Entities/EngagementMethod/Interfaces/IEngagementMethod';
import { ucwords } from '../../../../Helpers/StringHelper';
import { ContactsRequest } from '../../Api/ContactsRequest';
import { EngagementMethodsRequest } from '../../Api/EngagementMethodsRequest';
import { OrganisationsRequest } from '../../Api/OrganisationsRequest';
import TeacherDropDownRepository from '../../Api/TeacherDropDownRepository';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import Organisation from '../../Models/Organisation';
import Teacher from '../../Models/Teacher';
import UserType from '../../Models/UserType';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

type FormData = {
    organisation_id: string;
    title: string;
    firstname: string;
    surname?: string;
    jobrole: string;
    email?: string;
    telephone: string;
    owned_by_id: string;
    engagement_method_id?: string;
    engagement_date?: string;
    personnel_id?: string;
    education_package?: string;
};

const ContactsAddSisp: FunctionComponent<Props> = (props: Props) => {
    const contactsRepository = useContext(RepositoryFactoryContext).getApiRepository(new ContactsRequest());
    const engagementMethodRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new EngagementMethodsRequest(),
    );
    const userRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const organisationRepository = useContext(RepositoryFactoryContext).getApiRepository(new OrganisationsRequest());
    const teacherRepository = new TeacherDropDownRepository('', '/education/teachers/api');

    const focusRef = useRef<HTMLInputElement>(null);

    const dictionary = useContext(DictionaryContext);

    // Form State: General
    const [submitting, setSubmitting] = useState<boolean>(false);

    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        watch,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({ mode: 'all' });

    // Form State: Fields
    const [organisationId, setOrganisationId] = useState<number | undefined>(undefined);
    const [teacherSearch, setTeacherSearch] = useState<boolean>(false);
    const [teacherEmail, setTeacherEmail] = useState<string>('');
    const [claiming, setClaiming] = useState<boolean>(false);

    const newEmail = watch('email') ?? '';

    const [organisation, setOrganisation] = useState<OptionTypeBase | null>();
    const [ownedBy, setOwnedBy] = useState<OptionTypeBase | null>();
    const [engagementMethod, setEngagementMethod] = useState<OptionTypeBase | null>();
    const [teacher, setTeacher] = useState<OptionTypeBase | null>();

    const engagementDateValue = watch('engagement_date');
    const engagementDate = engagementDateValue ? new Date(engagementDateValue) : undefined;

    // Reset form
    const resetForm = () => {
        // Reset generic states
        setClaiming(false);
        setTeacherSearch(false);
        setTeacherEmail('');
        setOrganisationId(undefined);

        // Reset dropdown field states
        setOrganisation(null);
        setOwnedBy(null);
        setEngagementMethod(null);
        setTeacher(null);

        // Reset form values
        reset();
    };

    // On componentDidMount
    useEffect(() => {
        register('personnel_id');
        register('organisation_id', { required: 'This field is required.' });
        register('owned_by_id');
    }, []);

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        } else {
            resetForm();
        }
    }, [props.shown]);

    const loadOrganisations = (
        filter?: string,
        page?: number,
    ): Promise<{ value: number; label: string; sector: string }[]> => {
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return organisationRepository
            .search(query)
            .then((results) => {
                return _.map(
                    _.filter(results.results as Organisation[], (org: Organisation) => {
                        return _.includes(org?.name?.toLowerCase(), filter?.toLowerCase());
                    }),
                    (org: Organisation) => {
                        return {
                            value: org?.id as number,
                            label: org?.name,
                            sector: org?.sector,
                        };
                    },
                );
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get organisations - ' + (err?.message ?? err),
                });
                return [];
            });
    };

    const loadUsers = (filter?: string, page?: number): Promise<{ value: number; label: JSX.Element }[]> => {
        // Fetch all users for client account
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return userRepository
            .search(query)
            .then((results) => {
                return _.map(
                    _.filter(results.results as UserType[], (user: UserType) => {
                        return _.includes(user?.name?.toLowerCase(), filter?.toLowerCase());
                    }),
                    (user: UserType) => {
                        return OptionTypeBaseUserFormatter(user);
                    },
                );
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get users - ' + (err?.message ?? err),
                });
                return [];
            });
    };

    const loadEngagementMethods = (
        filter?: string,
        page?: number,
    ): Promise<{ value: number; label: string; iso: string }[]> => {
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return engagementMethodRepository
            .search(query)
            .then((results) => {
                const res = _.map(results.results as any, (engagementMethod: IEngagementMethod) => {
                    return {
                        value: engagementMethod.id ?? 0,
                        label: engagementMethod.engagement_method + ' - ' + engagementMethod.processing_ground ?? '',
                        iso: engagementMethod.iso,
                    };
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Engagement Methods - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return [];
            });
    };

    const loadTeachers = (
        organisationId: number,
        filter?: string,
        page?: number,
    ): Promise<{ value: number; label: string; edu_data_package: string }[]> => {
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        query.setExtendedParameters({
            organisationId: organisationId,
        });
        return teacherRepository
            .search(query)
            .then((results) => {
                return _.map(
                    _.filter(results.results as Teacher[], (teacher: Teacher) => {
                        return _.includes(teacher?.name?.toLowerCase(), filter?.toLowerCase());
                    }),
                    (teacher: Teacher) => {
                        return {
                            value: teacher?.id as number,
                            label: teacher?.name as string,
                            edu_data_package: teacher?.edu_data_package as string,
                            email: teacher?.email as string,
                        };
                    },
                );
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Teachers - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return [];
            });
    };

    const checkEmailIsUnique = async (email: string): Promise<boolean> => {
        return contactsRepository
            .post_action('check_email', undefined, { email: email })
            .then((results: any) => {
                return results.data.result;
            })
            .catch((err) => {
                return false;
            });
    };

    const handleAddRow = async (data: FieldValues, event?: BaseSyntheticEvent<unknown, any, any>): Promise<boolean> => {
        return contactsRepository
            .create(data)
            .then((results: any) => {
                props.onSuccess([results.data]);
                showBanner({
                    message: 'Contact created successfully',
                    level: LogLevel.SUCCESS,
                });
                const buttonClicked = (event?.nativeEvent as SubmitEvent | undefined)?.submitter;
                if (buttonClicked?.dataset.redirectTo) {
                    window.location.href = `/subscribers/contacts/view/${results.data.id}`;
                }
                return true;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create contact - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (data: FieldValues, event?: BaseSyntheticEvent<unknown, any, any>) => {
        event?.preventDefault();
        setSubmitting(true);
        if ((await validate()) && (await handleAddRow(data, event))) {
            props.onClose();
        } else {
            setSubmitting(false);
        }
    };

    const validate = async (): Promise<boolean> => {
        const organisationValid = organisationId === undefined ? false : true;
        if (!organisationValid) {
            setError(
                'organisation_id',
                { type: 'validate', message: 'Organisation is Required' },
                { shouldFocus: true },
            );
        }

        let emailValid = true;
        const emailValidator = lookupValidator('email', 'text');
        emailValid = emailValidator.validate(newEmail);

        if (emailValid) {
            emailValid = await checkEmailIsUnique(newEmail);
            if (!emailValid) {
                setError(
                    'email',
                    { type: 'validate', message: 'Email is already used by another contact' },
                    { shouldFocus: true },
                );
            }
        }

        const newValidationState = {
            email: emailValid,
            organisation: organisationValid,
        };
        return _.every(newValidationState);
    };

    return (
        <Sisp
            isOpen={props.shown}
            onCancel={() => {
                props.onClose();
            }}
            footerOverride={
                <>
                    <PendingButton
                        pending={submitting}
                        type="submit"
                        form="contact_form"
                        onClick={() => setSubmitting(false)}
                    >
                        Save
                    </PendingButton>
                    <PendingButton
                        redirect={true}
                        pending={submitting}
                        onClick={() => setSubmitting(false)}
                        type="submit"
                        form="contact_form"
                        variant="default"
                    >
                        Save & View
                    </PendingButton>
                </>
            }
        >
            <h4>Add a Contact</h4>
            <Form id="contact_form" onSubmit={handleSubmit(onSubmitForm)}>
                <Form.Group className={errors.organisation_id ? 'has-error' : ''}>
                    <Form.Label>{ucwords(dictionary['organisation'])}</Form.Label>
                    <AsyncDropDownPaginated
                        id={'organisation_id'}
                        value={organisation}
                        isInvalid={false}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                        classNamePrefix={'organisation-id-dropdown'}
                        customiseMenu={
                            <Button
                                href="/subscribers/organisations/add"
                                style={{
                                    width: 'calc(100% - 10px)',
                                    margin: '5px',
                                    textAlign: 'left',
                                }}
                                variant="secondary"
                            >
                                Add a New {ucwords(dictionary['organisation'])}
                            </Button>
                        }
                        onChange={(selected: OptionTypeBase) => {
                            if (selected.sector == 'School') {
                                setTeacherSearch(true);
                            } else {
                                setTeacherSearch(false);
                                setValue('education_package', undefined);
                                setValue('personnel_id', undefined);
                            }
                            clearErrors('organisation_id');
                            setOrganisationId(selected.value);
                            setOrganisation({ label: selected.label, value: selected.value });
                            setValue('organisation_id', selected.value);
                        }}
                        loadOptions={async (filter: string, _loadedOptions, { page }) => {
                            let res = await loadOrganisations(filter, page);
                            // Get has_more entry from results
                            const hasMore = res.find((obj) => obj.label === 'has_more');
                            // Remove has_more entry from main results
                            res = _.filter(res, (obj) => obj.label !== 'has_more');
                            return {
                                options: res,
                                hasMore: hasMore?.value as unknown as boolean,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                    />
                    {errors.organisation_id && <span className="help-block">{errors.organisation_id.message}</span>}
                </Form.Group>
                {teacherSearch ? (
                    <Form.Group className={errors.personnel_id ? 'has-error' : ''}>
                        <Form.Label>Choose a teacher</Form.Label>
                        <Form.Control
                            id={'education_package'}
                            type={'hidden'}
                            {...register('education_package')}
                        ></Form.Control>
                        <AsyncDropDownPaginated
                            id={'personnel_id'}
                            value={teacher}
                            isInvalid={false}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuPortalTarget={document.body}
                            classNamePrefix={'teacher-id-dropdown'}
                            customiseMenu={
                                <Button
                                    onClick={() => {
                                        setTeacherSearch(false);
                                        setValue('jobrole', '');
                                        setValue('education_package', undefined);
                                        setValue('personnel_id', undefined);
                                        unregister('personnel_id');
                                    }}
                                    style={{
                                        width: 'calc(100% - 10px)',
                                        margin: '5px',
                                        textAlign: 'left',
                                    }}
                                    variant="secondary"
                                >
                                    Add a New Teacher
                                </Button>
                            }
                            onChange={(selected: OptionTypeBase) => {
                                setTeacher({ value: selected.value, label: selected.label });
                                setTeacherEmail(selected.email);
                                setValue('personnel_id', selected.value);
                                setValue('education_package', selected.edu_data_package);
                                // Reset name fields in case they were input earlier
                                setValue('firstname', '');
                                setValue('surname', '');
                            }}
                            loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                let res = await loadTeachers(organisationId ?? 0, filter, page);
                                // Get has_more entry from results
                                const hasMore = res.find((obj) => obj.label === 'has_more');
                                // Remove has_more entry from main results
                                res = _.filter(res, (obj) => obj.label !== 'has_more');
                                return {
                                    options: res,
                                    hasMore: hasMore?.value as unknown as boolean,
                                    additional: {
                                        page: page + 1,
                                    },
                                };
                            }}
                        />
                        {errors.personnel_id && <span className="help-block">{errors.personnel_id.message}</span>}
                    </Form.Group>
                ) : (
                    <>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control autoComplete="off" {...register('firstname')} />
                        </Form.Group>
                        <Accordion>
                            <Form.Group>
                                <Form.Row className="row">
                                    <div className="col-md-6">
                                        <Form.Label className="pull-left">Last Name</Form.Label>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="pull-right">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                Add Title
                                            </Accordion.Toggle>
                                        </label>
                                    </div>
                                </Form.Row>
                                <Form.Control autoComplete="off" {...register('surname')} />
                            </Form.Group>
                            <Accordion.Collapse eventKey="0">
                                <Form.Group>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control autoComplete="off" {...register('title')} />
                                </Form.Group>
                            </Accordion.Collapse>
                        </Accordion>
                    </>
                )}

                <Form.Group className={errors.jobrole ? 'has-error' : ''}>
                    <Form.Label>Job Role</Form.Label>
                    <Form.Control {...register('jobrole', { required: 'This field is required.' })} />
                    {errors.jobrole && <span className="help-block">{errors.jobrole.message}</span>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Owned By</Form.Label>
                    <AsyncDropDownPaginated
                        id={'owned_by_id'}
                        value={ownedBy}
                        isInvalid={false}
                        isClearable={true}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                        classNamePrefix={'owned-by-dropdown'}
                        onChange={(selected: OptionTypeBase) => {
                            setOwnedBy({
                                label: selected.label,
                                value: selected.value,
                            });
                            setValue('owned_by_id', selected?.value ?? '');
                        }}
                        loadOptions={async (filter: string, _loadedOptions, { page }) => {
                            const res = await loadUsers(filter, page);
                            return {
                                options: res,
                                hasMore: false,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                    />
                </Form.Group>

                <Form.Group className={errors.email ? 'has-error' : ''}>
                    <Form.Row className="row">
                        <div className="col-md-6">
                            <Form.Label className="pull-left">Email</Form.Label>
                        </div>
                        {teacherEmail && (
                            <div className="col-md-6">
                                <label className="pull-right">
                                    {claiming ? (
                                        <span
                                            onClick={() => {
                                                setClaiming(false);
                                                setValue('email', '');
                                            }}
                                        >
                                            <a>Cancel</a>
                                        </span>
                                    ) : (
                                        <span
                                            onClick={() => {
                                                setClaiming(true);
                                            }}
                                        >
                                            <a>Claim</a>
                                        </span>
                                    )}
                                </label>
                            </div>
                        )}
                    </Form.Row>
                    {teacherEmail && !claiming ? (
                        <>
                            <div className="form-control" dangerouslySetInnerHTML={{ __html: teacherEmail }} />
                            <span className="help-block">
                                Why is this email obfuscated?{' '}
                                <a target="_blank" href="<?= kb_url('obfuscated-emails') ?>">
                                    Learn more
                                </a>
                                .
                            </span>
                        </>
                    ) : (
                        <>
                            <Form.Control
                                autoComplete="off"
                                {...register('email', {
                                    pattern: {
                                        // eslint-disable-next-line max-len
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'This email address is not valid.',
                                    },
                                })}
                            />
                            {errors.email && <span className="help-block">{errors.email.message}</span>}
                        </>
                    )}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Telephone</Form.Label>
                    <Form.Control autoComplete="off" {...register('telephone')} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Engagement Method</Form.Label>
                    <AsyncDropDownPaginated
                        id={'engagement_method_id'}
                        value={engagementMethod}
                        isInvalid={false}
                        isClearable={true}
                        menuPlacement="top"
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                        classNamePrefix={'engagement-method-dropdown'}
                        onChange={(selected: OptionTypeBase) => {
                            if (selected) {
                                setEngagementMethod({ value: selected.value, label: selected.label });
                                setValue('engagement_method_id', selected.value);
                                setValue('engagement_date', new Date().toISOString());
                            } else {
                                setEngagementMethod(undefined);
                                setValue('engagement_method_id', undefined);
                                setValue('engagement_date', undefined);
                            }
                        }}
                        loadOptions={async (filter: string, _loadedOptions, { page }) => {
                            const res = await loadEngagementMethods(filter, page);
                            return {
                                options: res,
                                hasMore: false,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                    />
                </Form.Group>

                {engagementMethod && (
                    <Form.Group className="engagement-method-date">
                        <Form.Label>Engagement Date</Form.Label>
                        <div style={{ justifyContent: 'flex-start', width: '100%' }}>
                            <DatePicker
                                {...register('engagement_date')}
                                className="dg-sisp-date-picker wide"
                                placeholderText="Engagement Date"
                                selected={engagementDate}
                                onChange={(date: any) => setValue('engagement_date', date)}
                                dateFormat="EEE, d MMM yyyy"
                                portalId="root-react-datepicker-portal"
                            />
                        </div>
                    </Form.Group>
                )}
            </Form>
        </Sisp>
    );
};

export default ContactsAddSisp;
