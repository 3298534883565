import { Modal, ModalType, Sisp } from '@sprint/sprint-react-components';
import React, { ReactElement, useImperativeHandle, useState } from 'react';
import { Button } from 'react-bootstrap';
import './LearnMoreModal.scss';

interface Props {
    sisp?: boolean;
}

export type LearnMoreModalType = {
    displayComponent: (e: Event, label: string, summary: string, description: string) => void;
};

const LearnMoreModal = React.forwardRef<LearnMoreModalType, Props>((props, ref): ReactElement => {
    const [show, setShow] = useState<boolean>(false);
    const [label, setLabel] = useState<string>('');
    const [summary, setSummary] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    useImperativeHandle(ref, () => ({
        displayComponent: (e: Event, label: string, summary: string, description: string) => {
            e.stopPropagation();
            e.preventDefault();
            setLabel(label);
            setSummary(summary);
            setDescription(description);
            setShow(true);
            // this will trigger any rootClose handlers
            document.body.click();
        },
    }));

    return props.sisp ? (
        <Sisp
            isOpen={show}
            onCancel={() => setShow(false)}
            footerOverride={
                <>
                    <Button onClick={() => setShow(false)}>Close</Button>
                </>
            }
            className="filter-controls learn-more-modal"
        >
            <h3>{label}</h3>
            <div dangerouslySetInnerHTML={{ __html: description }} />
        </Sisp>
    ) : (
        <Modal
            close={() => setShow(false)}
            isOpen={show}
            title={label}
            type={ModalType.INFO}
            className="learn-more-modal"
        >
            <div dangerouslySetInnerHTML={{ __html: description }} />
        </Modal>
    );
});

export default LearnMoreModal;
