'use strict';

import { faGraduationCap } from '@fortawesome/pro-duotone-svg-icons';
import { faCircle, faEllipsis, faPeopleGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { Button, Col, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../CommonComponents/Spinner/Spinner';
import { toggleTeacherSelect } from '../../../Entities/School/Actions/Actions';
import OverflowTooltip from '../../School/Components/Common/OverflowTooltip';
import { RootState } from '../../School/rootReducer';
import AvailableTeacherData from './AvailableTeacherData';
import './Teacher.scss';
import TeacherAvatar from './TeacherAvatar';

interface Props {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    roleId: string;
    role: string;
    faculty: string;
    seniority: string;
    email?: string;
    contactUrl?: string;
    schoolPhone?: string;
    isUnsubscribed: boolean;
    addTeacher: (id: string, roleId: string, name: string, email?: string) => void;
    unsubscribeTeacher: (id: string, name: string) => void;
    teachersUnsubscribing: any[];
    linkedInProfile?: string;
}

const Teacher: FunctionComponent<Props> = (props: Props) => {
    const dispatch = useDispatch();
    // Redux store values
    const { teachersSelected } = useSelector((state: RootState) => state.school);

    const addTeacher = () => {
        props.addTeacher(props.id, props.roleId, props.name, props.email);
    };

    const unsubscribeTeacher = () => {
        props.unsubscribeTeacher(props.id, props.name);
    };

    const addTeacherButton = () => {
        const disabled = props.isUnsubscribed;

        let button = (
            <Button
                id={`teacher-add-${props.id}`}
                onClick={() => addTeacher()}
                className="btn-sm mr-2"
                variant="success"
                disabled={disabled}
            >
                Add to Contacts
            </Button>
        );

        if (props.contactUrl) {
            button = (
                <Button href={props.contactUrl} variant="default" className="btn-sm mr-2" disabled={disabled}>
                    View Contact
                </Button>
            );
        }

        return props.isUnsubscribed ? (
            <OverlayTrigger
                overlay={(overlayProps) => (
                    <Tooltip id="seniority-tooltip" {...overlayProps}>
                        Unsubscribed
                    </Tooltip>
                )}
            >
                <span>{button}</span>
            </OverlayTrigger>
        ) : (
            button
        );
    };

    const moreActionsButton = () => {
        let buttonContent: JSX.Element = <>Unsubscribe</>;
        let onClick = unsubscribeTeacher.bind(this);

        if (props.teachersUnsubscribing.indexOf(props.id) > -1) {
            buttonContent = <Spinner />;
        }

        const email = 'teachers@sprint-education.co.uk';
        const subject = 'Incorrect Data Report - EDUK Teacher ' + props.id;
        let body = 'Original Name: ' + props.name;
        body += '\nCorrected Name:';
        body += '\nOriginal Role: ' + props.role;
        body += '\nCorrected Role:';
        body += '\nOriginal Faculty: Faculty';
        body += '\nCorrected Faculty:';

        return (
            <span className="dropdown-wrapper">
                <Dropdown className="button-dropdown">
                    <Dropdown.Toggle
                        disabled={props.isUnsubscribed}
                        variant="link"
                        className="btn-sm mr-2 more-actions"
                        id="dropdown-basic"
                    >
                        <FontAwesomeIcon icon={faEllipsis} rotation={90} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {!props.isUnsubscribed && <Dropdown.Item onClick={onClick}>{buttonContent}</Dropdown.Item>}
                        {/* Commented out until this feature is built up properly */}
                        {/* <Dropdown.Item href={`mailto:${email}?subject=${subject}&body=${encodeURIComponent(body)}`}>
                            Report as incorrect
                        </Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
            </span>
        );
    };

    const checkbox = () => {
        return (
            <Checkbox
                label=""
                isChecked={_.includes(teachersSelected, props.roleId)}
                disabled={props.isUnsubscribed || !!props.contactUrl}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    dispatch(toggleTeacherSelect(props.roleId, e.currentTarget.checked));
                }}
            />
        );
    };

    return (
        <>
            <Col className="teacher-card-wrapper">
                <div className="teacher-card">
                    <div className="teacher-card-col">
                        <div className="teacher-card-checkbox">
                            {props.isUnsubscribed || props.contactUrl ? (
                                <OverlayTrigger
                                    overlay={(overlayProps) => (
                                        <Tooltip id="checkbox-tooltip" {...overlayProps}>
                                            {props.isUnsubscribed ? 'Unsubscribed' : 'Already a Contact'}
                                        </Tooltip>
                                    )}
                                >
                                    <span>{checkbox()}</span>
                                </OverlayTrigger>
                            ) : (
                                checkbox()
                            )}
                        </div>
                        <div className="teacher-card-header border-right flex-wrapper">
                            <TeacherAvatar firstName={props.firstName} lastName={props.lastName} />
                            <div className="name-wrapper">
                                <h5 className="mt-0">
                                    <OverflowTooltip placement="right" tooltip={props.name} text={props.name} />
                                </h5>
                                <span title={props.role} className="teacher-role">
                                    <OverflowTooltip placement="right" tooltip={props.role} text={props.role} />
                                </span>
                            </div>
                        </div>
                        <div className="teacher-card-body-left">
                            <span className="flex-wrapper">
                                <OverlayTrigger
                                    overlay={(overlayProps) => (
                                        <Tooltip id="faculty-tooltip" {...overlayProps}>
                                            Faculty
                                        </Tooltip>
                                    )}
                                >
                                    <span className="fa-layers fa-fw mr-1">
                                        <FontAwesomeIcon icon={faCircle} color="#34566D" />
                                        <FontAwesomeIcon icon={faPeopleGroup} inverse transform={{ size: 8, x: 0 }} />
                                    </span>
                                </OverlayTrigger>
                                <span className="teacher-role">
                                    <OverflowTooltip
                                        placement="right"
                                        tooltip={props.faculty ?? '-'}
                                        text={props.faculty ?? '-'}
                                    />
                                </span>
                            </span>
                            <span className="flex-wrapper">
                                <OverlayTrigger
                                    overlay={(overlayProps) => (
                                        <Tooltip id="seniority-tooltip" {...overlayProps}>
                                            Seniority
                                        </Tooltip>
                                    )}
                                >
                                    <span className="fa-layers fa-fw mr-1">
                                        <FontAwesomeIcon icon={faCircle} color="#34566D" />
                                        <FontAwesomeIcon icon={faGraduationCap} inverse transform={{ size: 8, x: 0 }} />
                                    </span>
                                </OverlayTrigger>
                                <span className="teacher-role">
                                    <OverflowTooltip
                                        placement="right"
                                        tooltip={props.seniority ?? '-'}
                                        text={props.seniority ?? '-'}
                                    />
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="teacher-card-col border-right flex-wrapper">
                        <AvailableTeacherData
                            email={props.email}
                            schoolPhone={props.schoolPhone}
                            linkedInProfile={props.linkedInProfile}
                        />
                    </div>
                    <div className="teacher-card-col footer flex-wrapper">
                        {addTeacherButton()}
                        {moreActionsButton()}
                    </div>
                </div>
            </Col>
        </>
    );
};

export default Teacher;
