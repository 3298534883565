'use strict';

import DateHelper from '../../Helpers/DateHelper';
import * as DealStageState from '../../ValueObjects/DealStageState/DealStageState';
import Price from '../../ValueObjects/Price/Price';
import CampusEntity from '../CampusEntity/CampusEntity';
import Contact from '../Contact/Contact';
import Organisation from '../Organisation/Organisation';
import Quote from '../Quote/Quote';
import Sale from '../Sale/Sale';
import Task from '../Task/Task';
import User from '../User/User';
import DealLostReason from './DealLostReason/DealLostReason';
import DealPipeline from './DealPipeline/DealPipeline';
import DealPipelineStage from './DealPipeline/DealPipelineStage/DealPipelineStage';
import DealType from './DealType/DealType';
import CustomProperty from '../CustomProperty/CustomProperty';

export default class Deal extends CampusEntity {
    id = undefined;
    name = '';
    description = '';
    value = undefined;
    dealType = undefined;
    dealPipeline = undefined;
    currentStage = undefined;
    ownedBy = undefined;
    followUpDate = '';
    closeDate = '';
    dealLostReason = undefined;
    created = '';
    createdBy = undefined;
    modified = '';
    modifiedBy = undefined;
    relatesTo = '';
    relatesToContact = undefined;
    relatesToOrganisation = undefined;

    loadingLinks = false;
    quoteCount = 0;
    quotes = undefined;
    saleCount = 0;
    sales = undefined;
    taskCount = 0;
    tasks = undefined;

    deleted = undefined;

    static blank() {
        return new Deal();
    }

    static fromApi(deal) {
        const entity = Deal.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = deal.id;
        entity.name = deal.name;
        entity.description = deal.description;
        entity.value = deal.value ? Price.fromApi(deal.value) : undefined;
        entity.dealType = deal.type ? DealType.fromApi(deal.type) : undefined;
        entity.dealPipeline = deal.pipeline ? DealPipeline.fromApi(deal.pipeline) : undefined;
        entity.currentStage = deal.current_stage ? DealPipelineStage.fromApi(deal.current_stage) : undefined;
        entity.ownedBy = deal.owned_by ? User.fromApi(deal.owned_by) : undefined;
        entity.followUpDate = deal.follow_up_date;
        entity.closeDate = deal.close_date;
        entity.dealLostReason = deal.lost_reason ? DealLostReason.fromApi(deal.lost_reason) : undefined;
        entity.created = deal.created;
        entity.createdBy = deal.created_by ? User.fromApi(deal.created_by) : undefined;
        entity.modified = deal.modified;
        entity.modifiedBy = deal.modified_by ? User.fromApi(deal.modified_by) : undefined;
        entity.relatesTo = deal.relates_to;
        entity.relatesToContact = deal.relates_to_contact ? Contact.fromApi(deal.relates_to_contact) : undefined;
        entity.relatesToOrganisation = deal.relates_to_organisation
            ? Organisation.fromApi(deal.relates_to_organisation)
            : undefined;

        entity.loadingMoneyLinks = false;
        entity.quoteCount = deal.quote_count;
        entity.quotes = deal.quotes ? deal.quotes.map((quote) => Quote.fromApi(quote)) : undefined;
        entity.saleCount = deal.sale_count;
        entity.sales = deal.sales ? deal.sales.map((sale) => Sale.fromApi(sale)) : undefined;
        entity.taskCount = deal.task_count;
        entity.tasks = deal.tasks ? deal.tasks.map((task) => Task.fromApi(task)) : undefined;
        entity.customProperties = deal.custom_properties ? deal.custom_properties.map((custom_property) => CustomProperty.fromApi(custom_property)) : undefined;

        entity.deleted = deal.deleted;
        return entity;
    }

    static fromDataGridApi(deal) {
        const entity = Deal.blank();

        // Construct from expected API output object
        // Note: `is_logged_in` will not be set for the below Users, access via Client object
        entity.id = deal.id;
        entity.name = deal.name;
        entity.description = deal.description;
        entity.value = deal.value ? Price.fromApi(deal.value) : undefined;
        entity.dealType = deal.type ? DealType.fromApi(deal.type) : undefined;

        let deal_pipeline = deal.pipeline;
        let pipeline_stages = JSON.parse(deal.pipeline.stages);
        deal_pipeline.stages = pipeline_stages;
        deal_pipeline = DealPipeline.fromApi(deal_pipeline);

        entity.dealPipeline = deal.pipeline ? deal_pipeline : undefined;
        entity.currentStage = deal.stage ? DealPipelineStage.fromApi(deal.stage) : undefined;
        entity.ownedBy = deal.owned_by ? User.fromApi(deal.owned_by) : undefined;
        entity.followUpDate = deal.follow_up_date;
        entity.closeDate = deal.close_date;
        entity.dealLostReason = deal.lost_reason ? DealLostReason.fromApi(deal.lost_reason) : undefined;
        entity.created = deal.created_by.date;
        entity.createdBy = deal.created_by ? User.fromApi(deal.created_by.created_by) : undefined;
        entity.modified = deal.modified_by.date;
        entity.modifiedBy = deal.modified_by ? User.fromApi(deal.modified_by.modified_by) : undefined;
        entity.relatesTo = deal.relates_to_contact ? 'contact' : deal.relates_to_organisation ? 'organisation' : '';
        entity.relatesToContact = deal.relates_to_contact ? Contact.fromApi(deal.relates_to_contact) : undefined;
        entity.relatesToOrganisation = deal.relates_to_organisation
            ? Organisation.fromApi(deal.relates_to_organisation)
            : undefined;

        entity.deleted = deal.deleted;
        return entity;
    }

    static select(entityArray, id) {
        return entityArray.find((entity) => entity.matches(id));
    }

    matches(id) {
        return String(this.id) === String(id);
    }

    clone() {
        return Object.assign(Deal.blank(), this);
    }

    isFollowUpOverdue() {
        if (!this.followUpDate || !this.currentStage) return false;
        return DateHelper.isPast(this.followUpDate) && this.currentStage.stageState === DealStageState.OPEN;
    }

    isCloseOverdue() {
        if (!this.closeDate || !this.currentStage) return false;
        return DateHelper.isPast(this.closeDate) && this.currentStage.stageState === DealStageState.OPEN;
    }

    isOverdue() {
        return this.isFollowUpOverdue() || this.isCloseOverdue();
    }

    canLoadLinks() {
        return !this.loadingLinks && this.hasLinksToLoad();
    }

    hasLinksToLoad() {
        return (
            this.id !== undefined &&
            ((this.quoteCount > 0 && this.quotes === undefined) ||
                (this.saleCount > 0 && this.sales === undefined) ||
                (this.taskCount > 0 && this.tasks === undefined))
        );
    }

    _relatesToOrganisationId() {
        if (this.relatesToOrganisation) {
            return this.relatesToOrganisation.id;
        } else if (this.relatesToContact && this.relatesToContact.organisation) {
            return this.relatesToContact.organisation.id;
        } else {
            return 0;
        }
    }

    quoteAddUrl(client) {
        const contactId = this.relatesToContact ? this.relatesToContact.id : 0;
        const organisationId = this._relatesToOrganisationId();
        const dealId = this.id ? this.id : 0;

        return Quote.makeAddUrl(organisationId, contactId, dealId, client);
    }

    saleAddUrl(client, quoteId = 0) {
        const contactId = this.relatesToContact ? this.relatesToContact.id : 0;
        const organisationId = this._relatesToOrganisationId();
        const dealId = this.id ? this.id : 0;

        return Sale.makeAddUrl(quoteId, organisationId, contactId, dealId, client);
    }

    forSelect() {
        return {
            id: this.id,
            label:
                this.name +
                (this.value ? ' - ' + this.value.priceFormatted() : '') +
                (this.created ? ' [' + DateHelper.formattedString(this.created, false) + ']' : ''),
        };
    }

    forApi() {
        const formData = new FormData();

        // Note: FormData will transmit a string of 'undefined' if the field is empty, so check before appending.
        if (this.id) formData.append('id', this.id);
        if (this.name) formData.append('name', this.name);
        if (this.description) formData.append('description', this.description);
        if (this.value) formData.append('value', this.value.pennies);
        if (this.dealType) formData.append('type_id', this.dealType.id);
        if (this.dealPipeline) formData.append('pipeline_id', this.dealPipeline.id);
        if (this.currentStage) formData.append('current_stage', this.currentStage.name);
        if (this.currentStage) formData.append('current_stage_id', this.currentStage.id);
        if (this.ownedBy) formData.append('owned_by_id', this.ownedBy.id);
        if (this.followUpDate) formData.append('follow_up_date', this.followUpDate);
        if (this.closeDate) formData.append('close_date', this.closeDate);
        if (this.dealLostReason) formData.append('lost_reason_id', this.dealLostReason.id);
        if (this.relatesTo) formData.append('relates_to', this.relatesTo);
        if (this.relatesToContact) formData.append('relates_to_contact', this.relatesToContact.id);
        if (this.relatesToOrganisation) formData.append('relates_to_organisation', this.relatesToOrganisation.id);

        return formData;
    }
}
