/* eslint-disable max-len */
import {
    AppEvent,
    DropDown,
    EventBusInstance,
    isValidEmail,
    OptionTypeBase,
    Sisp,
} from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { SmtpAccountRequest } from '../../Api/SmtpAccountRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import { RepositoryFactoryContext } from '../../index';
import SmtpAccount from '../../Models/SmtpAccount';
import SmtpAccountEditState from '../../Models/SmtpAccountEditState';
import { SmtpAccountProvider } from '../../Models/SmtpProvider';
import GoogleAgreementModal from './GoogleAgreementModal';
import { smtpOptions, toSmtpOption } from './helpers';
import MicrosoftAgreementModal from './MicrosoftAgreementModal';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

const SmtpAccountEditSisp: FunctionComponent<Props> = (props: Props) => {
    const [oauthEmailEnabled, setOauthEmailEnabled] = useState<boolean>(false);

    const smtpAccountRepository = useContext(RepositoryFactoryContext).getApiRepository(new SmtpAccountRequest());
    const [shown, setShown] = useState<boolean>(false);

    const focusRef = useRef<HTMLInputElement>(null);

    const [rowId, setRowId] = useState<number>(0);

    const [userId, setUserId] = useState<number>(0);

    const [message, setMessage] = useState<SmtpAccountEditState>();

    const [editName, setEditName] = useState<string>('');
    const [editNameValid, setEditNameValid] = useState<boolean>(true);

    const [editEmail, setEditEmail] = useState<string>('');
    const [editEmailValid, setEditEmailValid] = useState<boolean>(true);

    const [editHost, setEditHost] = useState<string>('');
    const [editHostValid, setEditHostValid] = useState<boolean>(true);

    const [editUsername, setEditUsername] = useState<string>('');
    const [editUsernameValid, setEditUsernameValid] = useState<boolean>(true);

    const [editPassword, setEditPassword] = useState<string>('[password]');
    const [editPasswordValid, setEditPasswordValid] = useState<boolean>(true);

    const [editPort, setEditPort] = useState<string>('');
    const [editPortValid, setEditPortValid] = useState<boolean>(true);

    const [editSecurity, setEditSecurity] = useState<string>('None');
    const [editSecurityValid, setEditSecurityValid] = useState<boolean>(true);

    const [editProvider, setEditProvider] = useState<OptionTypeBase>(toSmtpOption(SmtpAccountProvider.PLAIN_SMTP));

    const [hostChanged, setHostChanged] = useState<boolean>(false);
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const [hostAndPasswordValid, setHostAndPasswordValid] = useState<boolean>(true);

    const [validatingSMTP, setValidatingSMTP] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const [showGoogleAgreement, setShowGoogleAgreement] = useState(false);
    const [showMicrosoftAgreement, setShowMicrosoftAgreement] = useState(false);

    useEffect(() => {
        if (shown) {
            if (document.getElementById('oauth-email-enabled')) {
                const value = (document.getElementById('oauth-email-enabled') as HTMLInputElement).value;

                setOauthEmailEnabled(!!value);
            }
            focusRef.current?.focus();
        }
        setShowErrorMessage(false);
    }, [shown]);

    useEffect(() => {
        setShowErrorMessage(false);
    }, [userId, editName, editEmail, editHost, editUsername, editPassword, editPort, editSecurity]);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<SmtpAccountEditState>) => {
            if (event.target !== props.uniqueKey) return;
            setMessage(event.message);
        });
    }, [shown]);

    const configureForMessage = () => {
        if (message) {
            setUserId(message.user);
            setRowId(message.id);
            setEditName(message.default_from_name);
            setEditEmail(message.default_from_email);
            setEditHost(message.smtp_host);
            setEditUsername(message.smtp_user);
            setEditPassword('[password]');
            setEditPort(message.smtp_port.toString());
            setEditSecurity(message.smtp_security);
            setEditProvider(toSmtpOption(message.provider));
            setShown(true);
        }
    };

    useEffect(configureForMessage, [message]);

    useEffect(() => {
        if (message && shown) {
            if (message.provider === editProvider.value) {
                configureForMessage();
            } else if (editProvider.value === SmtpAccountProvider.PLAIN_SMTP) {
                setRowId(message.id);
                setEditName(message.default_from_name);
                setEditEmail(message.default_from_email);
                setEditHost('');
                setEditUsername('');
                setEditPassword('');
                setEditPort('25');
                setEditSecurity('');
            }
        }
    }, [editProvider]);

    const validate = async (): Promise<boolean> => {
        let passwordCheck = true;
        if (hostChanged && !passwordChanged) {
            passwordCheck = false;
            setHostAndPasswordValid(passwordCheck);
        }

        const nameValid = !!editName;
        setEditNameValid(nameValid);

        const hostValid = !!editHost;
        setEditHostValid(hostValid);

        const usernameValid = !!editUsername;
        setEditUsernameValid(usernameValid);

        const passwordValid = !!editPassword;
        setEditPasswordValid(passwordValid);

        const emailValid = !!editEmail && isValidEmail(editEmail);
        setEditEmailValid(emailValid);

        const portValid = !!editPort && Number.isInteger(Number(editPort));
        setEditPortValid(portValid);

        if (passwordCheck && nameValid && hostValid && usernameValid && passwordValid && emailValid && portValid) {
            setValidatingSMTP(true);
            return true;
        }
        return false;
    };

    const handleEditRow = async (): Promise<boolean> => {
        const SmtpAccount: SmtpAccount = {
            id: rowId,
            default_from_email: editEmail,
            default_from_name: editName,
            smtp_host: editHost,
            smtp_port: Number(editPort),
            smtp_user: editUsername,
            smtp_pass: editPassword,
            smtp_security: editSecurity,
            user: userId,
            provider: editProvider.value,
        };

        const abortController = new AbortController();
        setAbortController(abortController);

        return smtpAccountRepository
            .update(SmtpAccount, undefined, abortController.signal)
            .then((results: any) => {
                props.onSuccess(results.data);
                setValidatingSMTP(false);
                return Promise.resolve(true);
            })
            .catch((err) => {
                setValidatingSMTP(false);
                if (err?.message == 'canceled') {
                    // axios cancel signal triggered
                    setErrorMessage('Request Cancelled');
                } else {
                    setErrorMessage(err?.message ?? err);
                }
                setShowErrorMessage(true);
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if (await validate()) {
            if (await handleEditRow()) {
                setShown(false);
            }
        }
    };

    return (
        <Sisp
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                abortController?.abort();
                if (!validatingSMTP) {
                    setShown(false);
                } else {
                    setValidatingSMTP(false);
                }
            }}
            cancelDuringSubmit
            validate={validate}
            showSubmitButton={editProvider.value === SmtpAccountProvider.PLAIN_SMTP}
        >
            {!hostAndPasswordValid && (
                <p className="alert alert-danger">
                    Host field has changed, please re-enter the password and try again.
                </p>
            )}
            {showErrorMessage && <p className="alert alert-danger">{errorMessage}</p>}
            <h4>Edit a Connected Email</h4>
            {validatingSMTP && (
                <>
                    <p className="alert alert-success">Validating SMTP account details.</p>
                    <div style={{ position: 'relative', alignItems: 'center' }}>
                        <Card
                            className="loading-spinner-container filter-loading-spinner"
                            style={{ background: '#f9f9f9' }}
                        >
                            <Spinner animation="border" role="status" />
                        </Card>
                    </div>
                </>
            )}
            {!validatingSMTP && (
                <>
                    <p>
                        To send day to day email from Campus you need to add an SMTP account for you and each of your
                        users.{' '}
                        <a target="_blank" href={KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.SEND_EMAILS)}>
                            Learn more
                        </a>
                        .
                    </p>
                    <Form
                        onSubmit={(e: FormEvent) => {
                            setShowErrorMessage(false);
                            onSubmitForm(e);
                        }}
                    >
                        {/* Provider */}
                        {oauthEmailEnabled && (
                            <Form.Group>
                                <Form.Label>
                                    Email Provider <span className="required-field-marker">*</span>
                                </Form.Label>
                                <DropDown
                                    value={editProvider}
                                    isInvalid={false}
                                    isClearable={false}
                                    menuPortalTarget={document.body}
                                    menuPosition="fixed"
                                    classNamePrefix={'provider-dropdown'}
                                    onChange={(selected: OptionTypeBase) => {
                                        setEditProvider(selected);
                                    }}
                                    options={smtpOptions}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!editSecurityValid && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}

                        {editProvider.value === SmtpAccountProvider.PLAIN_SMTP && (
                            <>
                                {/* Email Address */}
                                <Form.Group>
                                    <Form.Label>
                                        Email Address <span className="required-field-marker">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        type="text"
                                        isInvalid={!editEmailValid}
                                        value={editEmail || ''}
                                        onChange={(event) => {
                                            setEditEmail(event.target.value);
                                            setEditEmailValid(true);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {!editEmailValid && 'This field is required.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* From Name */}
                                <Form.Group>
                                    <Form.Label>
                                        From Name <span className="required-field-marker">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        isInvalid={!editNameValid}
                                        value={editName || ''}
                                        onChange={(event) => {
                                            setEditName(event.target.value);
                                            setEditNameValid(true);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {!editNameValid && 'This field is required.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* Host */}
                                <Form.Group>
                                    <Form.Label>
                                        Host <span className="required-field-marker">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        isInvalid={!editHostValid}
                                        value={editHost || ''}
                                        onChange={(event) => {
                                            setEditHost(event.target.value);
                                            setEditHostValid(true);
                                            setHostChanged(true);
                                            setHostAndPasswordValid(true);
                                        }}
                                        placeholder="e.g. mail.mycompany.com"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {!editHostValid && 'This field is required.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* Username */}
                                <Form.Group>
                                    <Form.Label>
                                        Username <span className="required-field-marker">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        isInvalid={!editUsernameValid}
                                        value={editUsername || ''}
                                        onChange={(event) => {
                                            setEditUsername(event.target.value);
                                            setEditUsernameValid(true);
                                        }}
                                        placeholder="e.g. bob@mycompany.com"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {!editUsernameValid && 'This field is required.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* Password */}
                                <Form.Group>
                                    <Form.Label>
                                        Password <span className="required-field-marker">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        isInvalid={!editPasswordValid}
                                        value={editPassword || ''}
                                        onChange={(event) => {
                                            setPasswordChanged(true);
                                            setEditPassword(event.target.value);
                                            setEditPasswordValid(true);
                                            setHostAndPasswordValid(true);
                                        }}
                                        placeholder="**********"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {!editPasswordValid && 'This field is required.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* Port */}
                                <Form.Group>
                                    <Form.Label>
                                        Port <span className="required-field-marker">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        isInvalid={!editPortValid}
                                        value={editPort || ''}
                                        onChange={(event) => {
                                            setEditPort(event.target.value);
                                            setEditPortValid(true);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {!editPortValid && 'This field is required.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* Security */}
                                <Form.Group>
                                    <Form.Label>
                                        Security <span className="required-field-marker">*</span>
                                    </Form.Label>
                                    <DropDown
                                        value={{ value: editSecurity, label: editSecurity }}
                                        isInvalid={false}
                                        isClearable={false}
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        classNamePrefix={'provider-dropdown'}
                                        onChange={(selected: OptionTypeBase) => {
                                            setEditSecurity(selected.value);
                                            setEditSecurityValid(true);
                                        }}
                                        options={[
                                            { value: 'None', label: 'None' },
                                            { value: 'SSL', label: 'SSL' },
                                            { value: 'TLS', label: 'TLS' },
                                        ]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {!editSecurityValid && 'This field is required.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>
                        )}
                        {editProvider.value === SmtpAccountProvider.MICROSOFT && (
                            <>
                                <Button disabled={!userId} onClick={() => setShowMicrosoftAgreement(true)}>
                                    Connect to Outlook 365
                                </Button>
                                {showMicrosoftAgreement && (
                                    <MicrosoftAgreementModal
                                        href={`/settings/connected_emails/authenticate_microsoft?smtp_user_id=${userId}&smtp_settings_id=${rowId}`}
                                        close={() => setShowMicrosoftAgreement(false)}
                                    />
                                )}
                            </>
                        )}
                        {editProvider.value === SmtpAccountProvider.GOOGLE && (
                            <>
                                <Button disabled={!userId} onClick={() => setShowGoogleAgreement(true)}>
                                    Connect to Gmail
                                </Button>
                                {showGoogleAgreement && (
                                    <GoogleAgreementModal
                                        href={`/settings/connected_emails/authenticate_google?smtp_user_id=${userId}&smtp_settings_id=${rowId}`}
                                        close={() => setShowGoogleAgreement(false)}
                                    />
                                )}
                            </>
                        )}
                    </Form>
                </>
            )}
        </Sisp>
    );
};

export default SmtpAccountEditSisp;
