import { AppEvent, EventBusInstance } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
declare function dictionaryValue(key: string): string;

export interface LimitCountType {
    used_count: number;
    limit: number;
}

export enum NearLimitMethod {
    PERCENTAGE = 'percentage',
    ONE_LESS = 'one_less',
}

interface Props {
    uniqueKey: string;
    limitName: string;
    nearLimitMethod?: string;
    percentLimit?: number;
}

const LimitBanner: FunctionComponent<Props> = (props: Props) => {
    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const defaultBannerState = {
        nearLimit: false,
        atLimit: false,
        dismissable: true,
    };
    const [bannerState, setBannerState] = useState(defaultBannerState);
    const [usedCount, setUsedCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(0);

    const calculateNearLimit = (used_count: number, limit: number): boolean => {
        switch (props.nearLimitMethod) {
            case NearLimitMethod.ONE_LESS:
                return limit - used_count === 1;
            case NearLimitMethod.PERCENTAGE:
            default:
                return (used_count / limit) * 100 >= props.percentLimit!;
        }
    };

    useEffect(() => {
        EventBusInstance.subscribe('limit-banner', (event: AppEvent<LimitCountType>) => {
            if (event.target !== props.uniqueKey) return;
            if (event.message.limit == null) return;
            if (event.message.used_count >= event.message.limit) {
                setBannerState({ ...defaultBannerState, atLimit: true, dismissable: false });
            } else {
                if (calculateNearLimit(event.message.used_count, event.message.limit)) {
                    setBannerState({ ...defaultBannerState, nearLimit: true });
                } else {
                    setBannerState(defaultBannerState);
                    setShown(false);
                }
            }
            setUsedCount(event.message.used_count);
            setLimit(event.message.limit);
        });
    }, []);

    useEffect(() => {
        if (bannerState.nearLimit || bannerState.atLimit) {
            setShown(true);
        }
    }, [bannerState.nearLimit, bannerState.atLimit]);

    const supportEmail = dictionaryValue('company.email_support');
    const mailToSupport = `mailto:${supportEmail}`;

    return (
        <>
            <div className="banner-container">
                <Alert
                    show={shown}
                    onClose={() => setShown(false)}
                    variant="danger"
                    dismissible={bannerState.dismissable}
                >
                    {bannerState.nearLimit && (
                        <>
                            <b>
                                You've used {usedCount}/{limit} {props.limitName} and are nearing your limit.
                            </b>{' '}
                            Unlock space for more by&nbsp;
                            <a
                                href={mailToSupport}
                                className="rdg-link"
                                style={{ color: '#ffffff', textDecoration: 'underline' }}
                            >
                                upgrading your plan.
                            </a>
                        </>
                    )}
                    {bannerState.atLimit && (
                        <>
                            <b>
                                You've reached your limit of {limit} {props.limitName}.
                            </b>{' '}
                            Unlock space for more by&nbsp;
                            <a
                                href={mailToSupport}
                                className="rdg-link"
                                style={{ color: '#ffffff', textDecoration: 'underline' }}
                            >
                                upgrading your plan.
                            </a>
                        </>
                    )}
                </Alert>
            </div>
        </>
    );
};

LimitBanner.defaultProps = {
    nearLimitMethod: NearLimitMethod.PERCENTAGE,
    percentLimit: 80,
};

export default LimitBanner;
