import { AsyncDropDownPaginated, DropDown, OptionTypeBase } from '@sprint/sprint-react-components';
import React, { FunctionComponent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import './CustomPropertyForm.scss';

export enum AvailablePropertyTypes {
    contacts = 'contacts',
    organisations = 'organisations',
    tasks = 'tasks',
    deals = 'deals',
}

// We set form values on the parent form/component using the props
interface Props {
    propertyType: AvailablePropertyTypes;
    customProperties: any;
    customPropertyValues: any;
    updateFormPropertyState: (customPropertyValues: any) => void;
    setPropertyValue: (key: any, value: any) => void;
}

const CustomPropertyForm: FunctionComponent<Props> = (props: Props) => {
    const { control, register } = useForm<FormData>({ mode: 'all' });

    const renderAllCustomProperties = () => {
        return props.customProperties.map((property: any, i: number) => {
            // Return if property type doesnt match or the property shouldn't be visible in a form.
            if (property.type != props.propertyType || !property.show_in_view || property.archived) return null;

            // Split the custom property key to usable values
            const propertyFormKey = property.key.replace(/-/g, '_');
            const propertyId = property.key.split('-')[1];

            // Pre-set existing values on the form
            let existingValue = props.customPropertyValues?.[propertyId] ?? '';
            if (property.data_type == 'date') {
                existingValue = props.customPropertyValues?.[propertyId] ?? null;
            }
            props.setPropertyValue(propertyFormKey, existingValue);

            // Render property
            if (property.data_type == 'select') {
                return (
                    <Form.Group key={i}>
                        <Form.Label>{property.name}</Form.Label>
                        <DropDown
                            value={
                                existingValue
                                    ? {
                                          value: existingValue,
                                          label: existingValue,
                                      }
                                    : null
                            }
                            isInvalid={false}
                            isClearable={true}
                            menuPortalTarget={document.body}
                            menuPosition="fixed"
                            onChange={(selected: OptionTypeBase) => {
                                props.updateFormPropertyState({
                                    ...props.customPropertyValues,
                                    [propertyId]: selected?.value,
                                });
                                props.setPropertyValue(propertyFormKey, selected?.value ?? '');
                            }}
                            options={property.options.map((option: any, i: number) => {
                                return {
                                    value: option.value,
                                    label: option.name,
                                };
                            })}
                            data-cy={'customProperty-' + propertyId}
                        />
                    </Form.Group>
                );
            } else if (property.data_type == 'checkbox') {
                const values = existingValue ? existingValue.split(/\r?\n/) : [];
                const initialSelection = values.map((value: any) => {
                    if (value.trim()) {
                        return {
                            value: value,
                            label: value,
                        };
                    }
                });

                return (
                    <Form.Group key={i}>
                        <Form.Label style={{ marginRight: '10px' }}>{property.name}</Form.Label>
                        <AsyncDropDownPaginated
                            className="custom-multi-select-dropdown"
                            id={`multi-${i}`}
                            isMulti={true}
                            isInvalid={false}
                            value={initialSelection}
                            onChange={(selected) => {
                                const values = selected.map((item) => item.value).join('\r\n');
                                props.updateFormPropertyState({
                                    ...props.customPropertyValues,
                                    [propertyId]: values,
                                });
                                props.setPropertyValue(propertyFormKey, values);
                            }}
                            loadOptions={() => {
                                return {
                                    options: property.options.map((option: any) => {
                                        return {
                                            value: option.value,
                                            label: option.name,
                                        };
                                    }),
                                };
                            }}
                        />
                    </Form.Group>
                );
            } else if (property.data_type == 'radio') {
                return (
                    <Form.Group key={i} className="custom-radio-buttons">
                        <Form.Label style={{ marginRight: '10px' }}>{property.name}</Form.Label>
                        {property.options.map((option: any, i: number) => {
                            return (
                                <Col key={property.data_type + propertyId + i}>
                                    <Row>
                                        <Form.Check
                                            inline={true}
                                            custom={true}
                                            {...register(propertyFormKey)}
                                            data-cy={'customProperty-' + propertyId + '-' + option.value}
                                            checked={option.value == existingValue}
                                            value={option.value}
                                            name={propertyFormKey}
                                            label={option.value}
                                            id={option.value + '-' + propertyId}
                                            type={property.data_type}
                                            onChange={(event) => {
                                                const id = event.target.id.replace(new RegExp(`-${propertyId}$`), '');
                                                props.updateFormPropertyState({
                                                    ...props.customPropertyValues,
                                                    [propertyId]: id,
                                                });
                                                props.setPropertyValue(propertyFormKey, id);
                                            }}
                                        />
                                    </Row>
                                </Col>
                            );
                        })}
                        <a
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                props.updateFormPropertyState({
                                    ...props.customPropertyValues,
                                    [propertyId]: undefined,
                                });
                                props.setPropertyValue(propertyFormKey, undefined);
                            }}
                        >
                            Clear
                        </a>
                    </Form.Group>
                );
            } else if (property.data_type == 'textarea') {
                return (
                    <Form.Group key={i}>
                        <Form.Label>{property.name}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            {...register(propertyFormKey)}
                            data-cy={'customProperty-' + propertyId}
                            value={existingValue ?? ''}
                            onChange={(event) => {
                                props.updateFormPropertyState({
                                    ...props.customPropertyValues,
                                    [propertyId]: event.target.value ?? '',
                                });
                                props.setPropertyValue(propertyFormKey, event.target.value ?? '');
                            }}
                        />
                    </Form.Group>
                );
            } else if (property.data_type == 'number') {
                return (
                    <Form.Group key={i}>
                        <Form.Label>{property.name}</Form.Label>
                        <Form.Control
                            type="number"
                            autoComplete="off"
                            {...register(propertyFormKey)}
                            data-cy={'customProperty-' + propertyId}
                            value={existingValue ?? ''}
                            onChange={(event) => {
                                props.updateFormPropertyState({
                                    ...props.customPropertyValues,
                                    [propertyId]: event.target.value ?? '',
                                });
                                props.setPropertyValue(propertyFormKey, event.target.value ?? '');
                            }}
                        />
                    </Form.Group>
                );
            } else if (property.data_type == 'date') {
                return (
                    <Form.Group key={i} className="custom-properties-date">
                        <Form.Label>{property.name}</Form.Label>
                        <br />
                        <Controller
                            name={propertyFormKey}
                            control={control}
                            data-cy={'customProperty-' + propertyId}
                            render={() => (
                                <DatePicker
                                    className="custom-properties-date-picker"
                                    placeholderText="Select date"
                                    selected={
                                        typeof existingValue === 'string' ? parseISO(existingValue) : existingValue
                                    }
                                    onChange={(date: Date) => {
                                        props.updateFormPropertyState({
                                            ...props.customPropertyValues,
                                            [propertyId]: date,
                                        });
                                        props.setPropertyValue(propertyFormKey, date);
                                    }}
                                    isClearable={true}
                                    dateFormat="dd/MM/yyyy"
                                />
                            )}
                        />
                    </Form.Group>
                );
            } else {
                return (
                    <Form.Group key={i}>
                        <Form.Label>{property.name}</Form.Label>
                        <Form.Control
                            autoComplete="off"
                            {...register(propertyFormKey)}
                            data-cy={'customProperty-' + propertyId}
                            value={existingValue ?? ''}
                            onChange={(event) => {
                                props.updateFormPropertyState({
                                    ...props.customPropertyValues,
                                    [propertyId]: event.target.value ?? '',
                                });
                                props.setPropertyValue(propertyFormKey, event.target.value ?? '');
                            }}
                        />
                    </Form.Group>
                );
            }
        });
    };

    return renderAllCustomProperties();
};

export default CustomPropertyForm;
