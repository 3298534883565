import { faCheck, faCircle, faStar, faThumbtack } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    AppEvent,
    EventBusInstance,
    ExtendedColumn,
    HoveroverButton,
    LogLevel,
    OptionTypeBaseUserFormatter,
    SearchQuery,
    SortOrder,
    UserFormatter,
    filter as filterTypes,
    showBanner,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RenderCellProps } from 'react-data-grid';
import NumberToLocaleStringComponent from '../../../../CommonComponents/NumberToLocaleString/NumberToLocaleString';
import { ucwords } from '../../../../Helpers/StringHelper';
import { ContactSavedViewsRequest } from '../../Api/ContactSavedViewsRequest';
import { CrmListsRequest } from '../../Api/CrmListsRequest';
import { OrganisationSavedViewsRequest } from '../../Api/OrganisationSavedViewsRequest';
import SimpleDataGridRepository from '../../Api/SimpleDataGridRepository';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { SavedInboundView } from '../../Components/SavedInboundView';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { UniqueKeyType } from '../../Models/Enums';
import UserType from '../../Models/UserType';
import { DictionaryContext, RepositoryFactoryContext } from '../../index';
import CampusDataGrid, { PromptMeta } from '../CampusDataGrid';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

export enum CrmListsColumnKey {
    ID,
    NAME,
    TYPE,
    SIZE,
    DESCRIPTION,
    CREATED_BY,
    LAST_UPDATED,
    SHARED,
    PINNED,
    STARRED,
}

const CrmListsTable: FunctionComponent<Props> = (props: Props) => {
    const copyListUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.COPY_ACTION);

    const dictionary = useContext(DictionaryContext);

    const listsRepository = useContext(RepositoryFactoryContext).getApiRepository<SavedInboundView>(
        new CrmListsRequest(),
    );
    const userRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());

    // Reload state to pass onto the DG
    const [reload, setReload] = useState(false);
    // Loading state to pass onto the DG
    const [isLoading, setIsLoading] = useState(false);

    const Columns: Record<CrmListsColumnKey, ExtendedColumn> = {
        [CrmListsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
        },
        [CrmListsColumnKey.NAME]: {
            key: 'name',
            sortKey: 'list_name',
            name: 'Name',
            sortable: true,
            frozen: true,
            renderCell: (formatterProps: RenderCellProps<any>) => {
                const listName = _.get(formatterProps.row, formatterProps.column.key);
                const id = formatterProps.row.id;
                const type = formatterProps.row.type;

                return (
                    <>
                        <a href={`/subscribers/${type.toLowerCase()}s/active_view/${id}`} className="rdg-link">
                            {listName}
                        </a>
                        <HoveroverButton
                            contents="Copy"
                            showHoverover={true}
                            eventBusMessageTarget={copyListUniqueKey}
                            eventBusMessage={formatterProps.row}
                        />
                    </>
                );
            },
            width: '4fr',
        },
        [CrmListsColumnKey.TYPE]: {
            key: 'type',
            sortKey: 'type',
            name: 'Type',
            sortable: true,
            width: '2fr',
        },
        [CrmListsColumnKey.SIZE]: {
            key: 'list_count',
            name: 'Size',
            description: 'List count is calculated when the list is saved.',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                return <NumberToLocaleStringComponent count={props.row.list_count} />;
            },
        },
        [CrmListsColumnKey.DESCRIPTION]: {
            key: 'description',
            name: 'Description',
            sortable: false,
            width: '3fr',
        },
        [CrmListsColumnKey.CREATED_BY]: {
            key: 'createdBy',
            sortKey: 'created_by',
            name: 'List Owner',
            renderCell: UserFormatter,
            sortable: true,
            width: '2fr',
        },
        [CrmListsColumnKey.LAST_UPDATED]: {
            key: 'modified',
            sortKey: 'modified',
            name: 'Last Updated',
            sortable: true,
            width: '2fr',
        },

        [CrmListsColumnKey.SHARED]: {
            key: 'shared',
            name: 'Shared',
            renderCell: (props: RenderCellProps<any>) => {
                const shared: boolean = props.row.shared ?? false;
                const iconUpdate = { ...props.row, shared: !shared };
                return shared ? (
                    <OverlayTrigger overlay={<Tooltip id="builtin-tooltip">Unshare</Tooltip>} placement="right">
                        <span
                            className="fa-layers fa-fw fa-lg"
                            style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                            onClick={() => handleIconToggle(iconUpdate)}
                        >
                            <FontAwesomeIcon icon={faCircle} style={{ color: '#2FC2AD' }} />
                            <FontAwesomeIcon icon={faCheck} inverse transform="shrink-8" />
                        </span>
                    </OverlayTrigger>
                ) : (
                    <span
                        className="fa-layers fa-fw fa-lg"
                        style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                        onClick={() => handleIconToggle(iconUpdate)}
                    />
                );
            },
            sortable: true,
            width: '1fr',
        },
        [CrmListsColumnKey.PINNED]: {
            key: 'pinned',
            name: 'Pinned',
            renderCell: (props: RenderCellProps<any>) => {
                const pinned: boolean = props.row.pinned ?? false;
                const iconUpdate = { ...props.row, pinned: !pinned };
                return pinned ? (
                    <OverlayTrigger overlay={<Tooltip id="builtin-tooltip">Unpin</Tooltip>} placement="right">
                        <span
                            className="fa-layers fa-fw fa-lg"
                            style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                            onClick={() => handleIconToggle(iconUpdate)}
                        >
                            <FontAwesomeIcon icon={faCircle} style={{ color: '#09c' }} />
                            <FontAwesomeIcon
                                icon={faThumbtack}
                                style={{ transform: 'rotate(-45deg)', bottom: '-1px', right: '-1px' }}
                                inverse
                                transform="shrink-8"
                            />
                        </span>
                    </OverlayTrigger>
                ) : (
                    <span
                        className="fa-layers fa-fw fa-lg"
                        style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                        onClick={() => handleIconToggle(iconUpdate)}
                    />
                );
            },
            sortable: true,
            width: '1fr',
        },
        [CrmListsColumnKey.STARRED]: {
            key: 'favourited',
            name: 'Starred',
            renderCell: (props: RenderCellProps<any>) => {
                const favourited: boolean = props.row.favourited ?? false;
                const iconUpdate = { ...props.row, favourited: !favourited };
                return favourited ? (
                    <OverlayTrigger overlay={<Tooltip id="builtin-tooltip">Unstar</Tooltip>} placement="right">
                        <span
                            className="fa-layers fa-fw fa-lg"
                            style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                            onClick={() => handleIconToggle(iconUpdate)}
                        >
                            <FontAwesomeIcon icon={faCircle} style={{ color: '#ffc252' }} />
                            <FontAwesomeIcon icon={faStar} inverse transform="shrink-8" />
                        </span>
                    </OverlayTrigger>
                ) : (
                    <span
                        className="fa-layers fa-fw fa-lg"
                        style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                        onClick={() => handleIconToggle(iconUpdate)}
                    />
                );
            },
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[CrmListsColumnKey.NAME],
        Columns[CrmListsColumnKey.TYPE],
        Columns[CrmListsColumnKey.SIZE],
        Columns[CrmListsColumnKey.DESCRIPTION],
        Columns[CrmListsColumnKey.CREATED_BY],
        Columns[CrmListsColumnKey.LAST_UPDATED],
        Columns[CrmListsColumnKey.SHARED],
        Columns[CrmListsColumnKey.PINNED],
        Columns[CrmListsColumnKey.STARRED],
    ];

    useEffect(() => {
        listenForCopy();
    }, []);

    useEffect(() => {
        // reset reload if true
        if (reload) setReload(false);
    }, [reload]);

    // listen for copy click
    const listenForCopy = () => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<any>) => {
            if (event.target !== copyListUniqueKey) return;

            const request =
                event.message.type == 'Contact' ? new ContactSavedViewsRequest() : new OrganisationSavedViewsRequest();
            const repository = new SimpleDataGridRepository<SavedInboundView>('', request.Url);

            repository
                .post_action('copy', event.message.id ?? 0)
                .then(() => {
                    showBanner({
                        message: 'Your email was successfully copied.',
                        level: LogLevel.SUCCESS,
                    });
                    setReload(true);
                })
                .catch((err) => {
                    showBanner({
                        message: err.message,
                        level: LogLevel.ERROR,
                    });
                });
        });
    };

    const filters: filterTypes.BasicFilter[] = [
        {
            title: 'List Owner',
            name: 'createdBy',
            loadOptions: () => {
                // Fetch all users for client account
                const query = new SearchQuery(1, 100, 'id', SortOrder.ASC, '');
                return userRepository
                    .search(query)
                    .then((results) => {
                        return {
                            options: _.map(results.results as UserType[], (user: UserType) => {
                                return OptionTypeBaseUserFormatter(user);
                            }),
                        };
                    })
                    .catch((err: any) => {
                        showBanner({
                            message: 'Failed to get users - ' + (err?.message ?? err),
                        });
                    });
            },
        },
        {
            title: 'Type',
            name: 'type',
            options: [
                {
                    value: 'Contact',
                    label: 'Contact',
                },
                {
                    value: 'Organisation',
                    label: ucwords(dictionary['organisation']) ?? 'Organisation',
                },
            ],
        },
    ];

    const handleIconToggle = (update: any) => {
        setIsLoading(true);
        listsRepository
            .update(update, update.type.toLowerCase())
            .then((results: any) => {
                EventBusInstance.publish({
                    topic: 'update-datagrid-rows',
                    message: {
                        entities: results,
                        target: props.dataGridUniqueKey,
                    },
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                showBanner({
                    message: err.message,
                    level: LogLevel.ERROR,
                });
            });
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_crm_lists.png',
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.CREATE_CONTACT_LIST),
        addOnClickOverride: () => {
            window.location.href = '/subscribers/contacts';
        },
    };

    return (
        <>
            <CampusDataGrid
                repository={listsRepository}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    addButtonOverride: (
                        <Dropdown
                            data-cy="list-create-dropdown"
                            id="list-create-dropdown"
                            className="react-bootstrap button-dropdown save-dropdown"
                        >
                            <Dropdown.Toggle className="react-bootstrap">Create List</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item data-cy="list-create-contacts" href="/subscribers/contacts">
                                    Contact
                                </Dropdown.Item>
                                <Dropdown.Item data-cy="list-create-organisations" href="/subscribers/organisations">
                                    {ucwords(dictionary['organisation']) ?? 'Organisation'}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ),
                    filterBarMeta: {
                        filters: filters,
                    },
                    alignLeft: true,
                }}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    frozenColumns: [],
                    columnOptions: Columns,
                    defaultSortColumn: 'modified',
                    forceReload: reload,
                    isLoading: isLoading,
                }}
                promptMeta={promptMeta}
            ></CampusDataGrid>
        </>
    );
};

export default CrmListsTable;
