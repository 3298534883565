import DataGridRequest from './DataGridRequest';
import SimpleDataGridRepository from './SimpleDataGridRepository';

interface IModel {
    id?: number;
}

export default interface RepositoryFactory {
    getApiRepository<TModel extends IModel>(request: DataGridRequest<TModel>): SimpleDataGridRepository<TModel>;
}

export class DataGridApiRepositoryFactory implements RepositoryFactory {
    public getApiRepository<TModel extends IModel>(request: DataGridRequest<TModel>): SimpleDataGridRepository<TModel> {
        return new SimpleDataGridRepository<TModel>('', request.Url);
    }
}
