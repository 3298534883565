import {
    ExportableField,
    SearchQuery,
    SearchQueryBuilder,
    SearchResult,
    sqlDateFormatter,
} from '@sprint/sprint-react-components';
import axios, { AxiosRequestConfig, AxiosResponse, GenericAbortSignal } from 'axios';
import { classToPlain } from 'class-transformer';
import { stringify } from 'csv-stringify/sync';
import _ from 'lodash';
import urlJoin from 'url-join';
import { v4 as uuidv4 } from 'uuid';
import HttpRequestService from '../../../CommonComponents/HttpRequestService/HttpRequestService';
import { SimpleFilterRequest } from '../Components/Filters';
import SimpleSearchResultCounts from '../Components/SimpleSearchResultCounts';
import DataGridRepository from './DataGridRepository';
interface SimpleType {
    id?: number;
}

export default class SimpleDataGridRepository<T extends SimpleType> implements DataGridRepository<T> {
    private _http: HttpRequestService;
    private _baseUrl: string;
    private _getRowsCancelToken: string;

    constructor(
        private _authToken: string,
        path: string,
    ) {
        this._http = new HttpRequestService(this._authToken);
        this._baseUrl = path;
        this._getRowsCancelToken = uuidv4();
    }

    private isSuccessCode(statusCode: number) {
        return statusCode >= 200 && statusCode <= 299;
    }

    private isClientErrorCode(statusCode: number) {
        return statusCode >= 400 && statusCode <= 499;
    }

    public async search(query: SearchQuery, id?: number): Promise<SearchResult<T, SimpleSearchResultCounts>> {
        const url = id ? urlJoin(this._baseUrl, id.toString()) : this._baseUrl;
        return this._http
            .get(urlJoin(url, `?${query.toQueryString()}`), this._getRowsCancelToken)
            .then((res: AxiosResponse<{ items: T[]; meta: SimpleSearchResultCounts }> | null) => {
                if (res && this.isSuccessCode(res.status)) {
                    const { items: data, meta: counts } = res.data;
                    return new SearchResult<T, SimpleSearchResultCounts>(data, counts);
                }
                throw new Error(res?.statusText);
            })
            .catch((err) => {
                if (axios.isCancel(err))
                    return new SearchResult<T, SimpleSearchResultCounts>([], new SimpleSearchResultCounts(), true);
                throw err;
            });
    }

    public async filter(filter: SimpleFilterRequest): Promise<SearchResult<T, SimpleSearchResultCounts>> {
        return this._http
            .post(this._baseUrl, filter, this._getRowsCancelToken)
            .then((res: AxiosResponse<{ items: T[]; meta: SimpleSearchResultCounts }> | null) => {
                if (res && this.isSuccessCode(res.status)) {
                    const { items: people, meta: counts } = res.data;
                    return new SearchResult<T, SimpleSearchResultCounts>(people, counts);
                }
                throw new Error(res?.statusText);
            })
            .catch((err) => {
                if (axios.isCancel(err))
                    return new SearchResult<T, SimpleSearchResultCounts>([], new SimpleSearchResultCounts(), true);
                throw err;
            });
    }

    public async create(entity: T, signal?: GenericAbortSignal): Promise<T> {
        return this._http.post(this._baseUrl, classToPlain(entity), undefined, { signal: signal }).then((res) => {
            if (this.isSuccessCode(res.status)) return res.data;
            if (this.isClientErrorCode(res.status)) {
                throw new Error(res.data?.error || 'An unknown error occurred');
            }
            throw new Error(res.statusText);
        });
    }

    public async update(entity: T, type?: string, signal?: GenericAbortSignal): Promise<T> {
        const url = type
            ? urlJoin(this._baseUrl, type, entity.id!.toString())
            : urlJoin(this._baseUrl, entity.id!.toString());
        delete entity.id; // we don't want the id in the request body
        return this._http.patch(url, classToPlain(entity), { signal: signal }).then((res) => {
            if (this.isSuccessCode(res.status)) return res.data;
            if (this.isClientErrorCode(res.status)) {
                throw new Error(res.data?.error || 'An unknown error occurred');
            }
            throw new Error(res.statusText);
        });
    }

    public async delete(ids: number[]): Promise<boolean> {
        const payload = JSON.stringify(ids);
        return this._http.deleteMany(this._baseUrl, payload).then((res) => {
            if (this.isSuccessCode(res.status)) return res.data;
            throw new Error(res.data?.error || 'An unknown error occurred');
        });
    }

    public async post_action(
        action: string,
        id?: number,
        data?: any,
        extraOptions?: AxiosRequestConfig,
    ): Promise<boolean> {
        const url = id ? urlJoin(this._baseUrl, id.toString(), action) : urlJoin(this._baseUrl, action);
        return this._http.post(url, data, undefined, extraOptions).then((res) => {
            if (this.isSuccessCode(res.status)) return res.data;
            if (this.isClientErrorCode(res.status)) {
                throw new Error(res.data?.error || 'An unknown error occurred');
            }
            throw new Error(res.statusText);
        });
    }

    public async export(
        entity: T,
        filter: SimpleFilterRequest,
        selectedFields: ExportableField[],
        onProgress?: (rows: number) => void,
        cancelCheck?: () => boolean,
        retryAttempted?: () => void,
    ): Promise<boolean> {
        const header = _.map(selectedFields, (selectedField) => selectedField.name);
        const rows = [header];

        const initialFilterResult = await this.get_export_data(1, filter);
        if (initialFilterResult.counts === null) throw new Error('Export Failed');

        for (let i = 1; i <= initialFilterResult.counts.totalPages; i++) {
            if (cancelCheck && cancelCheck()) return Promise.resolve(false);

            let filterResult: SearchResult<T, SimpleSearchResultCounts>;
            try {
                filterResult = i === 1 ? initialFilterResult : await this.get_export_data(i, filter);
                if (filterResult.results === null) throw new Error('Export Failed');
            } catch (ex) {
                if (retryAttempted) retryAttempted();
                // Give page another go after fixed backoff period - likely network errors
                filterResult = await new Promise((resolve) =>
                    setTimeout(async () => resolve(await this.get_export_data(i, filter)), 5000),
                );
                if (filterResult.results === null) throw new Error('Export Failed');
            }

            rows.push(
                ..._.map(filterResult.results, (result: any) =>
                    _.map(selectedFields, (selectedField) =>
                        (selectedField.formatter
                            ? selectedField.formatter(result, selectedField.key)
                            : String(_.get(result, selectedField.key) ?? '')
                        )
                            // Remove any rogue line endings in the source data
                            ?.replaceAll(/\r?\n|\r/g, ''),
                    ),
                ),
            );

            if (onProgress) {
                onProgress(
                    filterResult.counts.itemsPerPage * (filterResult.counts.currentPage - 1) +
                        filterResult.counts.itemCount,
                );
            }
        }

        // Final check for cancels, in case it was cancelled on the last page
        if (cancelCheck && cancelCheck()) return Promise.resolve(false);

        // Build and trigger download container
        const element = document.createElement('a');

        element.setAttribute(
            'href',
            'data:text/csv;charset=utf-8,' +
                encodeURIComponent(
                    stringify(rows, {
                        // Add the \ufeff UTF Byte Order Marker that is required for Excel to
                        // recognise the need to decode international characters in CSV files
                        bom: true,
                    }),
                ),
        );
        element.setAttribute(
            'download',
            `Exported_${classToPlain(entity)}_${sqlDateFormatter(new Date().toISOString(), true, true)
                .replaceAll(' ', '_')
                .replaceAll(':', '-')}.csv`,
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        // Not my cup of tea, but it seems this is the way™
        element.click();

        document.body.removeChild(element);
        return Promise.resolve(true);
    }

    private async get_export_data(
        page: number,
        filters: SimpleFilterRequest,
    ): Promise<SearchResult<T, SimpleSearchResultCounts>> {
        const useFilterRequest = !_.isEmpty(filters.filterRequest.selectedFilters);
        if (useFilterRequest) {
            return this.filter({
                ...filters,
                ...new SearchQueryBuilder(page, 500).build().toPOJO(),
            });
        }
        return this.search(new SearchQueryBuilder(page, 500).build());
    }
}
