import { SearchQueryBuilder, filter } from '@sprint/sprint-react-components';
import React from 'react';
import ClientTagPill from '../../../../CommonComponents/ClientTags/ClientTagPill';
import { APP_COLOURS } from '../../../../CommonComponents/Colours/AppColours';
import { ClientTagsRequest } from '../../Api/ClientTagsRequest';
import SimpleDataGridRepository from '../../Api/SimpleDataGridRepository';
import ClientTag from '../../Models/ClientTag';
import { ExportableExtendedColumn, FilterExtendedColumn } from '../CampusDataGrid';

const tagsRepository = new SimpleDataGridRepository<ClientTag>('', new ClientTagsRequest().Url);

interface IClientTagsColumn extends FilterExtendedColumn, ExportableExtendedColumn {}

const ClientTagsColumn: IClientTagsColumn = {
    key: 'tags',
    name: 'Tags',
    description: 'Your custom tags set on the report',
    sortable: false,
    width: 300,
    filterFieldType: filter.FieldType.ENUM_ARRAY,
    filterFieldAsyncOptions: (filter: string, page?: number) => {
        const queryBuilder = new SearchQueryBuilder(page, 100);
        return tagsRepository.search(queryBuilder.withFilter(filter).build()).then((result) => {
            const res: filter.EnumOption[] = result.results.map((t) => {
                // NOTE
                // We cannot use the ClientTagPill component directly here as it will not be rendered correctly when read from localstorage filters.
                //  See FilterLocalStorageManager for some trickery we do to be able to save basic components like spans to the LS (Logic around $$Symbol)
                //  This unfortunately doesn't work for custom React components and I've not been able to find a workaround.
                //  To avoid the filters simply displaying the description, I've used a basic span pill that looks identical to ClientTagPill
                const textColour =
                    getColourLuminance(t.hex_colour) > BRIGHTNESS_THRESHOLD ? APP_COLOURS.BLACK : APP_COLOURS.WHITE;
                return {
                    value: t.id ?? 0,
                    name: (
                        <span
                            className="badge"
                            style={{
                                borderRadius: '3px',
                                backgroundColor: '#' + t.hex_colour,
                                color: '#' + textColour,
                            }}
                        >
                            {t.name}
                        </span>
                    ),
                };
            });
            res.push({
                value: result.counts.currentPage < result.counts.totalPages,
                name: 'has_more',
            });
            return res;
        });
    },
    renderCell: (props: any) => {
        return props.row.tags?.map((t: ClientTag) => {
            return (
                <span key={t.id}>
                    <ClientTagPill tag={t} />{' '}
                </span>
            );
        });
    },
    exportFormatter: (entity: any) => {
        const tags: string[] = entity.tags?.map((t: ClientTag) => {
            return t.name;
        });
        return tags?.join(',') ?? '';
    },
};

export default ClientTagsColumn;
