export enum UserStatus {
    INVITED = 'Invited',
    ACTIVE = 'Active',
}

export enum UserPermissionLevel {
    ADMIN = 'Admin',
    MANAGER = 'Manager',
    USER = 'User',
}

export enum ImportStatus {
    COMPLETE = 'Complete',
    CANCELLED = 'Cancelled',
    CANCELLING = 'Cancelling',
}

export enum ImportReviewStatus {
    IMPORTED = 'Imported',
    DUPLICATE = 'Duplicate',
    ERROR = 'Error',
}

export enum CampaignStatus {
    SENT = 'Sent',
    AWAITING_REPORT = 'Awaiting Report',
    BUILDING = 'Building',
    SENDING = 'Sending',
    SCHEDULED = 'Scheduled',
}

export enum HTMLColors {
    SUCCESS = '#2fc2ad',
    PRIMARY = '#0099cc',
    WARNING = '#ffa706',
    DANGER = '#ed5b4e',
}

export enum ProcessingGround {
    CONSENT = 'Consent',
    CONTRACT = 'Contract',
    LEGAL_OBLIGATION = 'Legal Obligation',
    VITAL_INTEREST = 'Vital Interest',
    PUBLIC_INTEREST = 'Public Interest',
    LEGITIMATE_INTEREST = 'Legitimate Interest',
}

export enum TasksStatus {
    TO_DO = 'To Do',
    IN_PROGRESS = 'In Progress',
    COMPLETE = 'Complete',
    ON_HOLD = 'On Hold',
}

export enum TasksPriority {
    HIGHEST = 'Highest',
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low',
    LOWEST = 'Lowest',
}

export enum RelatesTo {
    ORGANISATION = 'Organisation',
    CONTACT = 'Contact',
    DEAL = 'Deal',
}

export enum SalesStatus {
    PAID = 'Paid',
    OVERDUE = 'Overdue',
    UNPAID = 'Unpaid',
}

export enum DateFilters {
    TODAY = 'Today',
    YESTERDAY = 'Yesterday',
    THIS_WEEK = 'This Week',
    THIS_MONTH = 'This Month',
    BETWEEN = 'Between',
}

export enum UniqueKeyType {
    ADD_SISP = 'add',
    EDIT_SISP = 'edit',
    PREVIEW_SISP = 'preview',
    EMBED_MODAL = 'embed',
    PREVIEW_MODAL = 'preview',
    ARCHIVE_ACTION = 'archive',
    COPY_ACTION = 'copy',
    SEND_ACTION = 'send',
    RESEND_ACTION = 'resend',
    RESTORE_ACTION = 'restore',
}

export enum UnsubscribedType {
    TEACHERS = 'teachers',
    SCHOOLS = 'schools',
    CONTACTS = 'contacts',
}

export enum ClientGroupType {
    CUSTOM_PROPERTIES = 'custom_properties',
}
