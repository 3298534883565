import {
    ExtendedColumn,
    filter as filterType,
    HoveroverButton,
    LogLevel,
    OptionTypeBase,
    showBanner,
    User,
    UserFormatter,
} from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Options } from 'react-select';
import { ucwords } from '../../../../Helpers/StringHelper';
import { QuotesRequest } from '../../Api/QuotesRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import UserPermissions from '../../HelperFunctions/UserPermissions';
import { DictionaryContext, RepositoryFactoryContext, UserContext } from '../../index';
import { DateFilters, UniqueKeyType } from '../../Models/Enums';
import QuoteCloseRowsPayload from '../../Models/QuoteCloseRowsPayload';
import QuotesEditState from '../../Models/QuotesEditState';
import QuoteType from '../../Models/QuoteType';
import CampusDataGrid, { PromptMeta } from '../CampusDataGrid';
import QuotesAddRedirect from './QuotesAddRedirect';
import QuotesDeleteCloseModal from './QuotesDeleteCloseModal';
import QuotesEditRedirect from './QuotesEditRedirect';
import './QuotesTable.scss';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum QuoteColumnKey {
    QUOTE,
    DATE,
    ORGANISATION,
    DEAL,
    CONTACT,
    OWNER,
    TOTAL,
}

enum FilterBarFiltersKey {
    STATUS,
    DATE,
}

const QuotesTable: FunctionComponent<Props> = (props: Props) => {
    const addSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP);
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const dictionary = useContext(DictionaryContext);

    const quotesRepository = useContext(RepositoryFactoryContext).getApiRepository(new QuotesRequest());

    const hasProducts = JSON.parse(String((document.getElementById('has_products') as HTMLInputElement).value));
    const user: User = useContext(UserContext);

    // State: DataGrid
    const [reload, setReload] = useState(false);

    useEffect(() => {
        if (reload) setReload(false);
    }, [reload]);

    const getEditColumnOptions = (selected: readonly OptionTypeBase[]): Options<OptionTypeBase> => {
        const availableOptions = [];
        const selectedKeys = selected.map((option) => option.value.toString());
        const keys = Object.keys(Columns);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i] as unknown as QuoteColumnKey;
            if (!selectedKeys.includes(key)) {
                availableOptions.push({
                    value: key,
                    label: Columns[key].name,
                    columnKey: Columns[key].key,
                });
            }
        }
        return [
            { label: 'Selected', options: selected },
            { label: 'Quotes', options: availableOptions },
        ];
    };

    // State: Columns
    const Columns: Record<QuoteColumnKey, ExtendedColumn> = {
        [QuoteColumnKey.QUOTE]: {
            key: 'quote_number',
            name: 'Quote',
            sortable: true,
            renderCell: (fprops) => {
                const id = (fprops.row as QuoteType).id;
                const url = 'quotes/view/' + id;
                const editEventBusMessage: QuotesEditState = {
                    id: id,
                };
                return (
                    <>
                        <a href={url}>{(fprops.row as QuoteType).quote_number}</a>
                        <HoveroverButton
                            contents="Edit"
                            showHoverover={true}
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={editEventBusMessage}
                        />
                    </>
                );
            },
            width: '1fr',
        },
        [QuoteColumnKey.DATE]: {
            key: 'date',
            name: 'Date',
            sortable: true,
            sortDescendingFirst: true,
            width: '1fr',
        },
        [QuoteColumnKey.DEAL]: {
            key: 'deal',
            name: 'Deal',
            sortable: true,
            width: '1fr',
        },
        [QuoteColumnKey.ORGANISATION]: {
            key: 'organisation_name',
            name: ucwords(dictionary['organisation']),
            sortable: true,
            renderCell: (fprops) => {
                const id = (fprops.row as QuoteType).organisation_id;
                const organisation_name = (fprops.row as QuoteType).organisation_name;
                if (id) {
                    const url = 'subscribers/organisations/view/' + id;
                    return <a href={url}>{organisation_name}</a>;
                } else {
                    return <>{organisation_name} </>;
                }
            },
            width: '1fr',
        },
        [QuoteColumnKey.CONTACT]: {
            key: 'contact_name',
            name: 'Contact',
            sortable: true,
            renderCell: (fprops) => {
                const id = (fprops.row as QuoteType).contact_id;
                const contact_name = (fprops.row as QuoteType).contact_name;
                if (id) {
                    const url = 'subscribers/contacts/view/' + id;
                    return <a href={url}>{contact_name}</a>;
                } else {
                    return <>{contact_name} </>;
                }
            },
            width: '1fr',
        },
        [QuoteColumnKey.OWNER]: {
            key: 'owner',
            name: 'Owner',
            sortable: true,
            renderCell: UserFormatter,
            width: '1fr',
        },
        [QuoteColumnKey.TOTAL]: {
            key: 'gross_total',
            name: 'Total',
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[QuoteColumnKey.QUOTE],
        Columns[QuoteColumnKey.DATE],
        Columns[QuoteColumnKey.DEAL],
        Columns[QuoteColumnKey.ORGANISATION],
        Columns[QuoteColumnKey.CONTACT],
        Columns[QuoteColumnKey.OWNER],
        Columns[QuoteColumnKey.TOTAL],
    ];

    // Filter Bar
    const FilterBarFilters: Record<FilterBarFiltersKey, filterType.BasicFilter> = {
        [FilterBarFiltersKey.STATUS]: {
            title: 'Status',
            name: 'status',
            options: [
                {
                    value: 'Open',
                    label: 'Open',
                },
                {
                    value: 'Converted',
                    label: 'Converted',
                },
            ],
        },
        [FilterBarFiltersKey.DATE]: {
            title: 'Date',
            name: 'date',
            options: [
                {
                    value: DateFilters.TODAY,
                    label: 'Today',
                },
                {
                    value: DateFilters.YESTERDAY,
                    label: 'Yesterday',
                },
                {
                    value: DateFilters.THIS_WEEK,
                    label: 'This Week',
                },
                {
                    value: DateFilters.THIS_MONTH,
                    label: 'This Month',
                },
                {
                    value: DateFilters.BETWEEN,
                    label: 'Between',
                },
            ],
        },
    };

    const getFilterBarFilters = (): filterType.BasicFilter[] => [
        FilterBarFilters[FilterBarFiltersKey.STATUS],
        FilterBarFilters[FilterBarFiltersKey.DATE],
    ];

    const onCloseSelectedRows = async (payload: QuoteCloseRowsPayload) => {
        return quotesRepository
            .update(payload)
            .then(() => {
                setReload(true);
                return true;
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to close Quotes - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_quotes.png',
        iconHeight: 180,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.ADD_QUOTE),
    };

    const productsNeededAllowedPromptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_products.png',
        entityPlural: 'Products',
        iconHeight: 80,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.MANAGE_PRODUCTS_SERVICES),
        header: "You Can't Create Quotes Yet",
        addLineText: 'Before creating a Quote you need to add your products(s)',
        addButtonLabel: 'Add Product',
        addOnClickOverride: () => {
            window.location.href = '/settings/products';
        },
    };

    const productsNeededForbiddenPromptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/no_products.png',
        entityPlural: 'Products',
        iconHeight: 80,
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.MANAGE_PRODUCTS_SERVICES),
        header: "You Can't Create Quotes Yet",
        additionalSubtext: [
            'Before creating a Quote you need to add your products(s).',
            "Unfortunately your user level doesn't allow you to do this. You'll need to ask an Admin or Manager user to add a product first.",
        ],
        hideAdd: true,
    };

    const returnCorrectProductsNeededPrompt = (): PromptMeta => {
        return UserPermissions.is_user_an_admin_or_manager(user)
            ? productsNeededAllowedPromptMeta
            : productsNeededForbiddenPromptMeta;
    };

    return (
        <>
            <CampusDataGrid
                repository={quotesRepository}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    extraActionBarMeta: {
                        getEditColumnOptionsDelegate: getEditColumnOptions,
                    },
                    filterBarMeta: {
                        filters: getFilterBarFilters(),
                    },
                }}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    columnOptions: Columns,
                    defaultColumns: DefaultColumns,
                    draggableColumns: true,
                    defaultSortColumn: 'modified',
                    forceReload: reload,
                }}
                addSispMeta={{
                    key: addSispUniqueKey,
                    sisp: QuotesAddRedirect,
                }}
                editSispMeta={{
                    sisp: QuotesEditRedirect,
                }}
                deleteModalMeta={{
                    modal: QuotesDeleteCloseModal,
                    additionalProps: {
                        onCloseRows: onCloseSelectedRows,
                    },
                }}
                promptMeta={hasProducts ? promptMeta : returnCorrectProductsNeededPrompt()}
            />
        </>
    );
};

export default QuotesTable;
