export enum EmailValidationResult {
    OK = 'ok',
    ERROR = 'error',
    SMTP_ERROR = 'smtp_error',
    SMTP_PROTOCOL = 'smtp_protocol',
    UNKNOWN_EMAIL = 'unknown_email',
    ATTEMPT_REJECTED = 'attempt_rejected',
    RELAY_ERROR = 'relay_error',
    ANTISPAM_SYSTEM = 'antispam_system',
    EMAIL_DISABLED = 'email_disabled',
    DOMAIN_ERROR = 'domain_error',
    OK_FOR_ALL = 'ok_for_all',
    DEAD_SERVER = 'dead_server',
    SYNTAX_ERROR = 'syntax_error',
    UNKNOWN = 'unknown',
    ACCEPT_ALL = 'accept_all',
    DISPOSABLE = 'disposable',
    SPAMTRAP = 'spamtrap',
    INVALID_MX = 'invalid_mx',
    INVALID_SYNTAX = 'invalid_syntax',
    INVALID = '-',
}

export enum EmailPrefix {
    FIRSTNAME_SURNAME = 'johnsmith',
    FIRSTNAME_DOT_SURNAME = 'john.smith',
    FIRSTNAME_SNAKE_SURNAME = 'john_smith',
    FIRSTNAME_KEBAB_SURNAME = 'john-smith',
    INITIAL_SURNAME = 'jsmith',
    INITIAL_DOT_SURNAME = 'j.smith',
    INITIAL_SNAKE_SURNAME = 'j_smith',
    INITIAL_KEBAB_SURNAME = 'j-smith',
    SURNAME = 'smith',
    SURNAME_DOT_INITIAL = 'smith.j',
    SURNAME_SNAKE_INITIAL = 'smith_j',
    SURNAME_KEBAB_INITIAL = 'smith-j',
    SURNAME_FIRSTNAME = 'smithjohn',
    SURNAME_DOT_FIRSTNAME = 'smith.john',
    SURNAME_SNAKE_FIRSTNAME = 'smith_john',
    SURNAME_KEBAB_SURNAME = 'smith-john',
    FIRSTNAME = 'john',
    FIRSTNAME_INITIAL = 'johns',
    INITIALS_TRIPLE = 'jas',
    INITIAL_ABBREVIATED = 'jsm',
    INITIAL_FIRST_THIRD_SURNAME = 'jsh',
    SURNAME_ABBREVIATION = 'smithjo',
    INITIAL_INITIAL = 'js',
}

export enum GenderOfPupils {
    BOYS = 'Boys',
    GIRLS = 'Girls',
    MIXED = 'Mixed',
    UNKNOWN = 'Unknown',
}

export enum BounceReason {
    BAD_DATA = 'Bad Data',
    REJECTED = 'Rejected',
}

export enum ModulePermissions {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    INVISIBLE = 'INVISIBLE',
}

export enum FeatureToggles {
    EDIN_EXPORT_SCHOOLS = 'EDIN_EXPORT_SCHOOLS',
    EDIN_EXPORT_TEACHERS = 'EDIN_EXPORT_TEACHERS',
}
