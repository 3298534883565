import _ from 'lodash';
import { User } from '@sprint/sprint-react-components';
import { UserPermissionLevel } from '../Models/Enums';

class UserPermissions {
    public static is_user_an_admin = (user: User): boolean => {
        // If user has a type of master then it has admin
        // level permissions
        if (user.type === UserPermissionLevel.ADMIN) {
            return true;
        }

        const userLevel: string = _.capitalize(user.level);
        if (userLevel === UserPermissionLevel.ADMIN) {
            return true;
        }

        return false;
    };

    public static is_user_an_admin_or_manager = (user: User): boolean => {
        if (UserPermissions.is_user_an_admin(user)) {
            return true;
        } else {
            const userLevel: string = _.capitalize(user.level);
            if (userLevel === UserPermissionLevel.MANAGER) {
                return true;
            }
        }

        return false;
    };
}

export default UserPermissions;
